// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emoji {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", "Twemoji Mozilla",
    "Noto Color Emoji", "EmojiOne Color", "Android Emoji", sans-serif;
  font-size: 1.4em;
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
  color: inherit;
  /* Ensure emoji renders in color */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-variant-emoji: emoji;
  /* Force color emoji */
  forced-color-adjust: none;
  /* Add a slight spacing */
  margin: 0 0.1em;
  /* Prevent selection */
  -webkit-user-select: none;
          user-select: none;
}

/* Ensure color rendering in different browsers */
@supports (-moz-appearance:none) {
  .emoji {
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Twemoji Mozilla", "Noto Color Emoji", sans-serif;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .emoji {
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji", sans-serif;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/shared/Emoji/Emoji.css"],"names":[],"mappings":"AAAA;EACE;qEACmE;EACnE,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;EAClC,kCAAkC;EAClC,yBAAyB;EACzB,sBAAsB;EACtB,yBAAyB;EACzB,yBAAyB;EACzB,eAAe;EACf,sBAAsB;EACtB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA,iDAAiD;AACjD;EACE;IACE;uDACmD;EACrD;AACF;;AAEA;EACE;IACE;oCACgC;EAClC;AACF","sourcesContent":[".emoji {\n  font-family: \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Apple Color Emoji\", \"Twemoji Mozilla\",\n    \"Noto Color Emoji\", \"EmojiOne Color\", \"Android Emoji\", sans-serif;\n  font-size: 1.4em;\n  vertical-align: middle;\n  display: inline-block;\n  line-height: 1;\n  color: inherit;\n  /* Ensure emoji renders in color */\n  -webkit-font-smoothing: antialiased;\n  text-rendering: optimizeLegibility;\n  -moz-osx-font-smoothing: grayscale;\n  font-variant-emoji: emoji;\n  /* Force color emoji */\n  forced-color-adjust: none;\n  /* Add a slight spacing */\n  margin: 0 0.1em;\n  /* Prevent selection */\n  user-select: none;\n}\n\n/* Ensure color rendering in different browsers */\n@supports (-moz-appearance:none) {\n  .emoji {\n    font-family: \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\",\n      \"Twemoji Mozilla\", \"Noto Color Emoji\", sans-serif;\n  }\n}\n\n@media screen and (-webkit-min-device-pixel-ratio:0) {\n  .emoji {\n    font-family: \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\",\n      \"Noto Color Emoji\", sans-serif;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
