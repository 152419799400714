import React, { useEffect } from 'react';
import '@n8n/chat/style.css';
import { createChat } from '@n8n/chat';
import './ChatWrapper.css';

const ChatWrapper = () => {
  useEffect(() => {
    // Get the webhook URL from the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const webhookUrl = urlParams.get('webhook');

    if (webhookUrl) {
      try {
        // Add a slight delay to ensure DOM is ready
        setTimeout(() => {
          createChat({
            webhookUrl: decodeURIComponent(webhookUrl),
            container: '.chat-container', // Specify the container
            theme: {
              mode: 'dark',
              colors: {
                primary: '#3b82f6',
                background: '#0a0a0a',
                text: '#ffffff',
                secondaryBackground: '#141414',
                secondaryText: '#94a3b8',
                border: 'rgba(255, 255, 255, 0.1)',
              }
            }
          });
        }, 100);
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    } else {
      console.error('No webhook URL provided');
    }
  }, []);

  return (
    <div className="chat-wrapper">
      <div className="chat-container" style={{ width: '100%', height: '100vh' }}></div>
    </div>
  );
};

export default ChatWrapper; 