import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import './Navbar.css';
import agentVersityLogo from '../../../assets/logos/agentversity.png';

const CheckIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

const AlertIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12.01" y2="16"></line>
  </svg>
);

const CloseIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const Navbar = () => {
  const { user, signInWithGoogle, signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (showMore) setShowMore(false);
    document.body.style.overflow = !isOpen ? 'hidden' : '';
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    document.body.style.overflow = '';
  };

  const handleClickOutside = (e) => {
    if (isOpen && !e.target.closest('.nav-wrapper') && !e.target.closest('.menu-toggle')) {
      setIsOpen(false);
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.body.style.overflow = '';
    };
  }, [isOpen, handleClickOutside]);

  const showNotification = (message, type = 'success') => {
    setNotification({ show: true, message, type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };

  const handleSignIn = async () => {
    try {
      await signInWithGoogle();
      showNotification('Welcome to AgentVersity! 🎉');
    } catch (error) {
      showNotification('Connection error', 'error');
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      showNotification('Logged out successfully 👋');
    } catch (error) {
      showNotification('Error signing out', 'error');
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false);
  };

  return (
    <>
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={handleLinkClick}>
            <img src={agentVersityLogo} alt="AgentVersity" className="logo" />
          </Link>

          <button 
            className="menu-toggle" 
            onClick={toggleMenu} 
            aria-label="Toggle menu"
            aria-expanded={isOpen}
          >
            <span className={`hamburger ${isOpen ? 'active' : ''}`}></span>
          </button>

          <div className={`nav-wrapper ${isOpen ? 'active' : ''}`}>
            <div className="nav-center">
              <div className="navbar-menu">
                <div className="nav-links">
                  <Link 
                    to="/" 
                    className="nav-item" 
                    onClick={() => scrollToSection('events')}
                  >
                    <span>Events</span>
                  </Link>
                  <Link 
                    to="/" 
                    className="nav-item" 
                    onClick={() => scrollToSection('app-gallery')}
                  >
                    <span>App Gallery</span>
                  </Link>
                  <Link 
                    to="/" 
                    className="nav-item" 
                    onClick={() => scrollToSection('tutorials')}
                  >
                    <span>Tutorials</span>
                  </Link>
                  <Link 
                    to="/" 
                    className="nav-item" 
                    onClick={() => scrollToSection('resources')}
                  >
                    <span>Resources</span>
                  </Link>
                  <div 
                    className="nav-item more-dropdown"
                    onMouseEnter={() => setShowMore(true)}
                    onMouseLeave={() => setShowMore(false)}
                  >
                    <span>More <i className="arrow-down">▼</i></span>
                    {showMore && (
                      <div className="dropdown-content">
                        <Link to="/about" onClick={() => setIsOpen(false)}>About Us</Link>
                        <Link to="/contact" onClick={() => setIsOpen(false)}>Contact</Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="nav-actions">
              {/* {user ? (
                <div className="user-menu">
                  <img 
                    src={user.user_metadata.avatar_url} 
                    alt="Profile" 
                    className="profile-image"
                  />
                  <button onClick={handleSignOut} className="signin-button">
                    Sign Out
                  </button>
                </div>
              ) : (
                <button onClick={handleSignIn} className="signin-button text-black" style={{ color: 'black' }}>
                  Sign in with Google
                </button>
              )} */}
              <div className="social-icons">
                <a href="https://x.com/Agent_Versity" target="_blank" rel="noopener noreferrer">
                  <svg viewBox="0 0 24 24" fill="currentColor">
                    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/agent-versity/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                  <svg viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                  </svg>
                </a>
                <a href="https://www.youtube.com/@CerebroneAI" target="_blank" rel="noopener noreferrer">
                  <svg viewBox="0 0 24 24" fill="currentColor">
                    <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className={`notification-slider ${notification.show ? 'show' : ''}`}>
        <div className={`notification-content ${notification.type}`}>
          <div className="notification-dot"></div>
          <span>{notification.message}</span>
        </div>
      </div>
    </>
  );
};

export default Navbar; 