// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* Primary Colors */
  --primary: #0cd3d3;      /* Main turquoise color */
  --primary-dark: #0bbfbf; /* Darker shade for hover states */
  
  /* Background Colors */
  --bg-dark: #000000;      /* Black background */
  --bg-card: rgba(255, 255, 255, 0.03);  /* Transparent white for cards */
  --bg-hover: rgba(12, 211, 211, 0.1);   /* Transparent turquoise for hovers */
  
  /* Border Colors */
  --border-light: rgba(255, 255, 255, 0.1);  /* Transparent white borders */
  --border-active: #0cd3d3;  /* Turquoise borders */
  
  /* Text Colors */
  --text-primary: #FFFFFF;  /* White text */
  --text-secondary: #94a3b8; /* Gray text */
  
  /* Gradient Colors */
  --gradient-overlay: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB,OAAO,yBAAyB;EAClD,uBAAuB,EAAE,kCAAkC;;EAE3D,sBAAsB;EACtB,kBAAkB,OAAO,qBAAqB;EAC9C,oCAAoC,GAAG,gCAAgC;EACvE,mCAAmC,IAAI,qCAAqC;;EAE5E,kBAAkB;EAClB,wCAAwC,GAAG,8BAA8B;EACzE,wBAAwB,GAAG,sBAAsB;;EAEjD,gBAAgB;EAChB,uBAAuB,GAAG,eAAe;EACzC,yBAAyB,EAAE,cAAc;;EAEzC,oBAAoB;EACpB,4EAA4E;AAC9E;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb","sourcesContent":[":root {\n  /* Primary Colors */\n  --primary: #0cd3d3;      /* Main turquoise color */\n  --primary-dark: #0bbfbf; /* Darker shade for hover states */\n  \n  /* Background Colors */\n  --bg-dark: #000000;      /* Black background */\n  --bg-card: rgba(255, 255, 255, 0.03);  /* Transparent white for cards */\n  --bg-hover: rgba(12, 211, 211, 0.1);   /* Transparent turquoise for hovers */\n  \n  /* Border Colors */\n  --border-light: rgba(255, 255, 255, 0.1);  /* Transparent white borders */\n  --border-active: #0cd3d3;  /* Turquoise borders */\n  \n  /* Text Colors */\n  --text-primary: #FFFFFF;  /* White text */\n  --text-secondary: #94a3b8; /* Gray text */\n  \n  /* Gradient Colors */\n  --gradient-overlay: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
