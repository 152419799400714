// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Apply font family to root */
:root {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  font-family: inherit;  /* Will inherit from root */
  background-color: #0a0a0a;
  color: #ffffff;
}

* {
  box-sizing: border-box;
  font-family: inherit;  /* Ensure all elements inherit the font */
}

/* Add smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Add custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444444;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE;gEAC8D;AAChE;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,oBAAoB,GAAG,2BAA2B;EAClD,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB,GAAG,yCAAyC;AAClE;;AAEA,yBAAyB;AACzB;EACE,uBAAuB;AACzB;;AAEA,yBAAyB;AACzB;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* Apply font family to root */\n:root {\n  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,\n    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n\n.App {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: inherit;  /* Will inherit from root */\n  background-color: #0a0a0a;\n  color: #ffffff;\n}\n\n* {\n  box-sizing: border-box;\n  font-family: inherit;  /* Ensure all elements inherit the font */\n}\n\n/* Add smooth scrolling */\nhtml {\n  scroll-behavior: smooth;\n}\n\n/* Add custom scrollbar */\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n::-webkit-scrollbar-track {\n  background: #1a1a1a;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #333333;\n  border-radius: 5px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #444444;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
