import React, { useState, useEffect } from 'react';
import { supabase } from '../../../config/supabaseClient';
import './SubscribeForm.css';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);

  // Add auto-hide functionality for status messages
  useEffect(() => {
    let timer;
    if (status.message) {
      timer = setTimeout(() => {
        setStatus({ type: '', message: '' });
      }, 3000); // Hide message after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [status]);

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ type: '', message: '' });

    // Validate email
    if (!validateEmail(email)) {
      setStatus({
        type: 'error',
        message: 'Please enter a valid email address'
      });
      setLoading(false);
      return;
    }

    try {
      // Check if email already exists
      const { data: existingSubscriber } = await supabase
        .from('subscribers')
        .select('id, status')
        .eq('email', email.toLowerCase())
        .single();

      if (existingSubscriber) {
        if (existingSubscriber.status === 'unsubscribed') {
          // Reactivate subscription
          const { error: updateError } = await supabase
            .from('subscribers')
            .update({ status: 'active' })
            .eq('id', existingSubscriber.id);

          if (updateError) throw updateError;
          
          setStatus({
            type: 'success',
            message: 'Welcome back! Your subscription has been reactivated.'
          });
        } else {
          setStatus({
            type: 'info',
            message: 'You are already subscribed!'
          });
        }
      } else {
        // Add new subscriber
        const { error: insertError } = await supabase
          .from('subscribers')
          .insert([{ email: email.toLowerCase() }]);

        if (insertError) throw insertError;

        setStatus({
          type: 'success',
          message: 'Thank you for subscribing!'
        });
      }

      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      setStatus({
        type: 'error',
        message: 'An error occurred. Please try again later.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubscribe} className="subscribe-form">
      <div className="input-group">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          disabled={loading}
          className={status.type === 'error' ? 'error' : ''}
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Subscribing...' : 'Subscribe'}
        </button>
      </div>
      {status.message && (
        <div className={`status-message ${status.type}`}>
          {status.message}
        </div>
      )}
    </form>
  );
};

export default SubscribeForm; 