import React from 'react';
import { allApps } from '../../../data/appsData';
import './Apps.css';

const Apps = () => {
  const handleAppClick = (app) => {
    window.open(app.link, '_blank');
  };

  return (
    <div className="apps-page">
      <div className="apps-header">
        <div className="header-badge">App Gallery</div>
        <h1>Explore Our <span className="highlight">AI Apps</span></h1>
        <p>Discover the full collection of AI-powered applications built by our community.</p>
      </div>

      <div className="apps-content">
        <div className="apps-grid">
          {allApps.map((app, index) => (
            <div key={index} className="app-card">
              <div className="app-image">
                <img src={app.image} alt={app.title} />
                <div className="image-overlay"></div>
              </div>
              <div className="app-content">
                <h3>{app.title}</h3>
                <p>{app.description}</p>
                <div className="app-actions">
                  <button 
                    onClick={() => handleAppClick(app)} 
                    className="try-app-btn"
                  >
                    Try Now
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Apps; 