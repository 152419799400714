import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/common/Loading';
import Navbar from './components/layout/Navbar/Navbar';
import Footer from './components/layout/Footer/Footer';
import BuildFastBot from './components/features/BuildFastBot/BuildFastBot';
import ChatWrapper from './components/features/ChatWrapper/ChatWrapper';
import './App.css';
import ScrollToTop from './components/common/ScrollToTop';
import Apps from './components/pages/Apps/Apps';

// Lazy load components
const Home = React.lazy(() => import('./components/home/Home'));
const Events = React.lazy(() => import('./components/pages/Events/Events'));
const Resources = React.lazy(() => import('./components/pages/Resources/Resources'));
const AppShowcase = React.lazy(() => import('./components/home/AppShowcase/AppShowcase'));
const SignIn = React.lazy(() => import('./components/features/SignIn/SignIn'));
const LaunchPad = React.lazy(() => import('./components/home/LaunchPad/LaunchPad'));
const ResourcePage = React.lazy(() => import('./components/pages/ResourcePage/ResourcePage'));
const AboutUs = React.lazy(() => import('./components/pages/AboutUs/AboutUs'));
const Contact = React.lazy(() => import('./components/pages/Contact/Contact'));

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <div className="App">
            <Navbar />
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/events" element={<Events />} />
                <Route path="/resources/*" element={<Resources />} />
                <Route path="/app-showcase" element={<AppShowcase />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/launchpad" element={<LaunchPad />} />
                <Route path="/chat" element={<ChatWrapper />} />
                <Route path="/resources/:resourceId" element={<ResourcePage />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/apps" element={<Apps />} />
              </Routes>
            </Suspense>
            <Footer />
            <BuildFastBot />
          </div>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
