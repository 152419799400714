export const allApps = [
  {
    title: "FirstImpress.ai",
    description: "FirstImpress makes it simple to bring your imagination to life. Generate crystal-clear, stunning AI images in just minutes—your creativity, our technology.",
    image: "https://images.unsplash.com/photo-1552664730-d307ca884978",
    link: "https://firstimpress.ai/",
  },
  {
    title: "TravelAgent",
    description: "Your AI-powered travel companion. Get personalized travel recommendations, itineraries, and real-time assistance for your perfect journey.",
    image: "https://images.unsplash.com/photo-1436491865332-7a61a109cc05",
    link: "https://cerebrone.app.n8n.cloud/webhook/71e88e03-43b6-4854-a458-30e97593ca77/chat",
  },
  {
    title: "Rich - AI Chatbot",
    description: "Your smart AI partner for task support and natural conversations.",
    image: "https://images.unsplash.com/photo-1531746790731-6c087fecd65a",
    link: "https://cerebrone.app.n8n.cloud/webhook/6d10e043-a484-4ce0-a3ea-c12726372a4d/chat",
  },
  {
    title: "Interview Agent",
    description: "Your AI interviewer. Get instant feedback and insights on your answers.",
    image: "https://images.unsplash.com/photo-1618477388954-7852f32655ec",
    link: "https://cerebrone.app.n8n.cloud/webhook/5f3ce6fc-ddca-4dc7-a506-ba4b02638598/chat"
  
  },
  {
    title: "Chat with PDF",
    description: "Interactive document analysis powered by AI. Ask questions and get instant answers from your PDFs.",
    image: "https://images.unsplash.com/photo-1618477388954-7852f32655ec",
    link: "/webhook/e985d15f-b2f6-456d-be15-97e0b1544a40/chat"
  }
]; 